import { render, staticRenderFns } from "./SwipeButton.vue?vue&type=template&id=6052811e&scoped=true&"
import script from "./SwipeButton.vue?vue&type=script&lang=js&"
export * from "./SwipeButton.vue?vue&type=script&lang=js&"
import style0 from "./SwipeButton.vue?vue&type=style&index=0&id=6052811e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6052811e",
  null
  
)

export default component.exports